<template>
  <v-container class="mt-3">
    <v-row no-gutters>
      <v-col cols="12">
        <ErrorComponent
          :title="
            pageName === 'articles'
              ? $t('product.article_not_found')
              : $t('product.product_not_found')
          "
          :description="$t('product.product_not_found_description')"
          :link="link"
          :buttonText="buttonText"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ErrorComponent from '@/modules/common/components/ErrorComponent';

export default {
  name: 'ProductNotFound',
  components: { ErrorComponent },
  computed: {
    link() {
      return this.$route?.query?.link;
    },

    buttonText() {
      return this.$t('product.explore', { name: this.$route?.query?.name });
    },

    pageName() {
      return this.$route?.query?.name;
    },
  },
};
</script>
